import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import cerseiChangelogData from '@pages/cersei/docs/changelog-cersei.json5';
import receiptChangelogData from '@pages/receipt/docs/changelog-receipt.json5';
import refundChangelogData from '@pages/refund/docs/changelog-refund.json5';
import orderChangelogData from '@pages/order/docs/changelog-order.json5';
import paymentChangelogData from '@pages/payment/docs/changelog-payment.json5';
import ticketDistributionChangelogData from '@pages/ticket-distribution/docs/changelog-ticket-distribution.json5';
import seatingArrangementChangelogData from '@pages/seating-arrangement/docs/changelog-seating-arrangement.json5';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Changelog = makeShortcode("Changelog");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "changelog-sales"
    }}>{`Changelog Sales`}</h1>
    <Changelog changelogData={[cerseiChangelogData, receiptChangelogData, refundChangelogData, orderChangelogData, paymentChangelogData, ticketDistributionChangelogData, seatingArrangementChangelogData]} mdxType="Changelog" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      